@charset "UTF-8";

body {
  margin: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1f1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::placeholder {
  color: #c5c5c5;
  /* opacity: 1; */
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c5c5c5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c5c5c5;
}

/* Pages */

/* Login Page & Register Page */

.login-page, .register-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.login-box, .register-box {
  width: 320px;
  height: fit-content;
  background-color: #fff;
  box-shadow: 5px 10px 20px #c1c1c1;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.login-box h1, .register-box h1 {
  font-size: 2rem;
  margin: 0;
  margin-top: 50px;
  text-align: center;
}

.login-box form, .register-box form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.login-box form input:not(:last-child), .register-box form input:not(:last-child) {
  width: 200px;
  height: 25px;
  outline: none;
  border: none;
  border-bottom: 1px solid #c5c5c5;
}

.login-box form input:last-child, .register-box form input:last-child {
  width: 200px;
  height: 30px;
  background-color: #fff;
  outline: none;
  border: 1px solid #000;
  border-radius: 0.25rem;
}

.login-box form input:last-child:hover, .register-box form input:last-child:hover {
  cursor: pointer;
  transition-duration: 150ms;
  background-color: #c5c5c5;
}

.login-box p, .register-box p {
  margin-bottom: 50px;
}

/* Dashboard */

.dashboard .dashboard-header {
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Components */

/* Chatroom Menu */
.chat-room-menu {
  grid-area: chat-room-menu;
  background-color: #f1f1f1;
  top: 75px;
  /* height: calc(100vh - 98px); */
  max-height: calc(100vh - 225px);
  width: 320px;
  /* transition-duration: 350ms; */
}

.chat-room-menu .contact-nav {
  width: 100%;
  height: 50px;
  background-color: #c8c8c8;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-room-menu .contact-nav h2 {
  margin: 0;
  padding: 0;
}

.chat-room-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: calc(100vh - 325px);
  overflow-y: auto; /* ToDo does not look good! */
}

.chat-room-menu ul li {
  display: grid;
  grid-template-columns: auto 1fr auto 35px;
  background-color: #c8c8c8;
  margin-top: 5px;
  width: 100%;
  height: 50px;
  line-height: 50px;
}

.chat-room-menu ul li:hover {
  cursor: pointer;
}

.contact-name {
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
  font-size: 1.125rem;
}

.contact-last-message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 100px;
}

.contact-time-ago {
  padding-left: 5px;
  padding-right: 5px;
  font-weight: bold;
  white-space: nowrap;
}

.contact-new-msg-count div {
  background-color: #e24949;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-top: 5px;
  margin-right: 5px;
  line-height: 25px;
  text-align: center;
}

/* Chat Room */

/* .chat-room {
  grid-area: chat-room;
  width: calc(100% - 320px);
  height: calc(100vh - 75px);
  background-color: #8f9715;
  box-sizing: border-box;
  border-left: 1px solid #c5c5c5;
} */

.chat-room .room-header {
  height: 50px;
  width: 100%;
  background-color: #8c8c8c;
}

.chat-room .room-header h1 {
  margin: 0;
  padding: 0;
  padding-left: 1rem;
  font-size: 1.75rem;
  line-height: 50px;
}

.chat-room .message-container {
  width: 100%;
  height: calc(100% - 125px);
  background-color: #f1f1f1;
}

.chat-room .room-input-menu {
  height: 75px;
  width: 100%;
  background-color: #8c8c8c;
}

.chat-room .room-input-menu form {
  display: grid;
  grid-template-columns: 0.25fr 10fr 0.125fr auto 0.25fr;
  grid-template-areas: ". input . btn .";
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.chat-room .room-input-menu form .message-input {
  height: 30px;
  line-height: 30px;
  font-size: 1.125rem;
  border-radius: 0.25rem;
  border: 1px solid #808080;
  grid-area: input;
}

.chat-room .room-input-menu form .message-input:focus,
.chat-room .room-input-menu form .message-input:active {
  outline: none;
}

.chat-room .room-input-menu form .send-btn {
  height: 30px;
  font-weight: bold;
  font-size: 1.125rem;
  grid-area: btn;
}

.chat-room .room-input-menu form .send-btn:hover {
  cursor: pointer;
}

/* Chat Messages */

.chat-messages {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-y: auto;
  list-style: none;
}

.chat-messages li {
  width: 100%;
  min-height: 0px;
  line-height: 30px;
  overflow: auto;
  word-break: break-all;
  word-wrap: break-word;
}

.chat-messages li:not(:first-child) {
  margin-top: 15px;
}

.chat-messages li div {
  display: flex;
  max-width: 50%;
  min-height: 30px;
  align-items: center;
  padding: 0.5rem;
}

.chat-messages .my-msg div {
  background-color: #90ee90;
  float: right;
  border-radius: 0.5rem 0 0 0.5rem;
}

.chat-messages .msg div {
  background-color: #add8e6;
  float: left;
  border-radius: 0 0.5rem 0.5rem 0;
}

/* .dashboard-main-content {
  display: grid;
  grid-template-columns: 320px 100%;
  grid-template-rows: 1fr 200px;
  grid-template-areas:
    'chat-room-menu chat-room '
    'user chat-room ';
} */

.user {
  grid-area: user;
  background-color: #f1f1f1;
  box-sizing: border-box;
  border-top: 1px solid #c5c5c5;
}

.user .info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
}

.user .info img {
  border: 2px solid #000;
  border-radius: 50%;
}

.user .info h1 {
  font-size: 1.25rem;
  box-sizing: border-box;
  margin: 0;
  padding-top: 0.5rem;
}

.user .logout-form {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.user .logout-form input {
  display: block;
  width: 100px;
  height: 25px;
  border-radius: 0.5rem;
  border-width: 1px;
  font-weight: bold;
}

.user .logout-form input:hover {
  cursor: pointer;
  background-color: #d1d1d1;
  transition-duration: 200ms
}

/* Extra small devices (phones, 768px and down) */
@media only screen and (max-width: 768px) {
  .dashboard-header {
    height: 75px;
  }

  .menu-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0.5rem;
    height: 75px;
    width: 50px;
  }

  .dashboard-main-content {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    grid-template-areas:
      'chat-room '
      'chat-room ';
  }

  .chat-room-menu {
    grid-area: chat-room-menu;
    display: none;
    position: absolute;
    width: 100%;
    height: calc(100vw - 200px - 75px);
  }

  .user {
    grid-area: user;
    display: none;
    position: absolute;
    width: 100%;
    height: 200px;
    bottom: 0;
  }

  .chat-room {
    grid-area: chat-room;
    width: 100%;
    min-width: 320px;
    height: calc(100vh - 75px);
    background-color: #8f9715;
    box-sizing: border-box;
    border-left: 1px solid #c5c5c5;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {

} */

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

  .dashboard-main-content {
    display: grid;
    grid-template-columns: 320px 100%;
    grid-template-rows: 1fr 200px;
    grid-template-areas:
      'chat-room-menu chat-room '
      'user chat-room ';
  }

  .chat-room-menu {
    background-color: #f1f1f1;
    top: 75px;
    max-height: calc(100vh - 225px);
    width: 320px;
    display: block;
  }

  .user {
    background-color: #f1f1f1;
    box-sizing: border-box;
    border-top: 1px solid #c5c5c5;
    display: block;
  }

  .chat-room {
    grid-area: chat-room;
    width: calc(100% - 320px);
    height: calc(100vh - 75px);
    background-color: #8f9715;
    box-sizing: border-box;
    border-left: 1px solid #c5c5c5;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}